var YouLi = YouLi ? YouLi : {};
YouLi.Validation = YouLi.Validation ? YouLi.Validation : {};

(function (YouLi) {
    (function (Validation) {

        YouLi.Validation.IsValidFileType = function (file, validFileTypes) {

            const fileExtension = YouLi.Common.Utils.GetFileExtension(file.name);
            let mimeType = YouLi.Common.Utils.MimeType(fileExtension);

            if (file.type)
                mimeType = file.type;   // override if we can (some Windows browsers don't work with this for types like CSV - YOULI-3250)

            return validFileTypes.includes(mimeType);
        }

        YouLi.Validation.Init = function () {

            // Australian date validation (browser validation in Chrome will fail without this)
            $.validator.methods.date = function (value, element) {
                return this.optional(element) || moment(value, "DD/MM/YYYY") || moment(value, "D MMM YYYY");
            };

            $.validator.setDefaults({
                highlight: function (element) {
                    $(element).closest('.form-group').addClass('has-error');
                },
                unhighlight: function (element) {
                    $(element).closest('.form-group').removeClass('has-error');
                },
                ignore: ":hidden:not([data-force-val='true'])"   // do not ignore hidden fields if they have a special flag set
                //We need to confirm what the folowing does before we use it
                //errorElement: 'span',
                //errorClass: 'help-block',
                //errorPlacement: function (error, element) {
                //    if (element.parent('.input-group').length) {
                //        error.insertAfter(element.parent());
                //    } else {
                //        error.insertAfter(element);
                //    }
                //}
            });
        }

    }(YouLi.Validation ? YouLi.Validation : {}));
}(YouLi ? YouLi : {}));

(function ($) {

   
}(jQuery));

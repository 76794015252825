window.onload = function () {
    DisableTooltipsOnSmall();
}

window.onresize = function () {
    DisableTooltipsOnSmall();
}

DisableTooltipsOnSmall = function () {
    var width = $(window).width();
    if (width <= 767) {
        $('[data-toggle="tooltip"]').tooltip('destroy');
    } else {
        $('[data-toggle="tooltip"]').tooltip();
    }
}